<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'班级列表'}]"></layout-breadcrumb>
    </div>
    <Card>
      <p slot="title">
        <Icon type="md-list-box" size="20"></Icon>
          班级管理
      </p>
      <div class="commonclass-query">
        <div>
          <Form ref="formInline" :model="query" inline>
            <FormItem prop="commonClassName ">
              <Input type="text" v-model="query.commonClassName " placeholder="班级名称" clearable></Input>
            </FormItem>
            <FormItem prop="enterYear">
              <Select v-model="query.enterYear" style="width:200px" placeholder="届号" clearable
                      @change="queryCommonClassList">
                <Option v-for="(item,index) in enterYearList" :value="item" :key="index">{{ item }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryCommonClassList">查询</Button>
            </FormItem>
          </Form>
        </div>
        <div>
          <Button type="success" icon="md-add" @click="showNewModal">创建班级</Button>
        </div>
      </div>
      <div>
        <div>
          <Table border :columns="columns12" :data="pageInfo">
            <template slot-scope="{row}" slot="action">
              <Button size="small" style="margin-right: 5px" @click="editClass(row)">编辑班级</Button>
              <Button type="primary" size="small" style="margin-right: 5px" @click="getStudent(row)">查看学生</Button>
              <Button type="info" size="small" style="margin-right: 5px" @click="getHomeWork(row)">查看作业</Button>
            </template>
          </Table>
        </div>
        <div style="text-align: center;margin-top: 20px;">
          <Page :total="pageData.total" :page-size="pageData.pageSize" @on-change="changePageNo"/>
        </div>
      </div>
    </Card>
    <Modal draggable reset-drag-position sticky footer-hide
           v-model="showNewStatus"
           :title="newForm.id > 0 ? '编辑班级' : '创建班级'">
      <Form ref="formValidate" :model="newForm" :rules="ruleValidate" :label-width="80">
        <FormItem label="名称" prop="name">
          <Input v-model="newForm.name" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem label="学届" prop="enterYear">
          <Select v-model="newForm.enterYear" style="width:200px" placeholder="届号" clearable>
            <Option v-for="(item,index) in enterYearList" :value="item" :key="index">{{ item }}</Option>
          </Select>
        </FormItem>
        <FormItem label="编号" prop="number">
          <Select v-model="newForm.number" filterable allow-create style="width:200px" @on-create="handleCreate" clearable>
            <Option
                v-for="(item,index) in numberList" :key="index" :label="item+'班'" :value="item">
            </Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" :loading="loadingSubmit" @click="handleSubmit('formValidate')">提交</Button>
          <Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      nowIndex: '',
      nowItem: '',
      showNewStatus: false,
      pageInfo: [],
      enterYearList: [],
      query: {
        commonClassName: '',
        enterYear: '',
      },
      numberList:[
        '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20'
      ],
      loadingSubmit: false,
      newForm: {
        id: 0,
        name: '',
        enterYear: '',
        number: ''
      },
      pageData: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      columns12: [
        {
          title: '名称',
          key: 'name'
        },
        {
          title: '学届',
          key: 'enterYear'
        },
        {
          title: '操作',
          slot: 'action',
          width: 300,
          align: 'center'
        }
      ],
      ruleValidate: {
        name: [
          {required: true, message: '名称必填', trigger: 'blur'}
        ],
        enterYear: [
          {required: true, message: '学届必选', trigger: 'change', type: 'number'}
        ],
        number: [
          {required: true, message: '编号必填', trigger: 'change'}
        ],
      }
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    for (let i = year; i >= 2015; i--) {
      this.enterYearList.push(i);
    }
    this.queryCommonClassList()
  },
  methods: {
    handleCreate (val) {
      this.numberList.unshift(val);
    },
    //
    editClass(item){
      this.newForm = item
      this.showNewStatus = true
    },
    // 查看学生列表
    getStudent(item) {
      this.$jump('/teacher-student', {id: item.id, name: item.name})
    },
    // 查看作业
    getHomeWork(item) {
      this.$jump('/teacher-homeWork', {id: item.id, enterYear:item.enterYear,name: item.name})
    },
    deleteCommonClass(index) {
      this.$http.deleteCommonClass(
          this.pageInfo[index].id
      ).then(res => {
        if (res.status == 1) {
          this.$Message.success('删除成功！');
          this.queryCommonClassList();
        }
      });
    },
    remove(index) {
      this.$Modal.confirm({
        title: '删除',
        content: '确定要删除此班级吗？',
        onOk: () => {
          this.deleteCommonClass(index);
        },
        onCancel: () => {

        }
      });
    },
    editCommonClassInfo(data) {
      this.$http.editTeacherCommonClassInfo(
          data.id,
          data.name,
          data.enterYear,
          data.number
      ).then(res => {
        this.loadingSubmit =false
        if (res.status == 1) {
          this.$Message.success('编辑成功！');
          this.newCancel();
          this.queryCommonClassList();
        }
      });
    },
    show(index) {
      this.nowIndex = index;
      this.nowItem = this.pageInfo[index];
      this.showStatus = true;
    },
    createCommonClass(data) {
      this.$http.createTeacherCommonClass(
          data.name,
          data.enterYear,
          data.number
      ).then(res => {
        this.loadingSubmit =false
        if (res.status == 1) {
          this.$Message.success('创建成功!');
          this.newCancel();
          this.queryCommonClassList();
        }
      });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let data = this.newForm
          this.loadingSubmit =true
          if(data.id > 0){
            this.editCommonClassInfo(data);
          }else{
            this.createCommonClass(data);
          }
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    newCancel() {
      this.newForm = {
        name: '',
        enterYear: '',
        number: ''
      };
      this.showNewStatus = false;
    },
    showNewModal() {
      this.showNewStatus = true;
    },
    changePageNo(val) {
      this.pageData.current = val;
      this.queryCommonClassList();
    },
    queryCommonClassList() {
      this.$http.queryTeacherCommonClassList(
          this.pageData.current,
          this.pageData.pageSize,
          this.query.enterYear,
          this.query.commonClassName,
      ).then(res => {
        if (res.status == 1) {
          this.pageInfo = res.data.records;
          this.pageData.total = res.data.total;
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
  }
}
</script>

<style scoped lang="less">
.commonclass-query {
  display: flex;
}
</style>
